import React from "react"
import Seo from "../components/Seo/Seo"
import { Link } from "gatsby";

const NotFoundPage = () => (
	< >
		<Seo title="404: Not found" />

		<div className="contentPage content404 page">

			<h1>404</h1>
			<h2>4-Oh-No!-4: Whatever you are looking for... it's not here.</h2>

			<section>
				<p>I mean, whatever you were looking for, I am sure it's about somewhere</p>
				<p>Although we recently updated our website and it may be that the content is no longer available.</p>
				<p>If not I am very sorry, but we do have lots of other lovely stuff on our <Link to="/">homepage</Link>, why not check that out; its right <Link to="/">here</Link>.</p>
			</section>

			<section>
				<p>Still here? ...</p>
				<p>I'm not sure what you are expecting from me... I really don't have anything else for you.</p>
				<p>I don't even think this page has been spell checked correctly or anything... it's not supposed to be something anyone sees to be honest.</p>
				<p>Something or somebody pretty much had to mess up for you to get here.</p>
				<p>Seriously, I would head over to the <Link to="/">homepage</Link> if I were you.</p>
				<p>It's much nicer there.</p>
				<p>It has pictures</p>
				<p>This page doesn't have pictures.</p>
				<p>Just this text... which frankly is getting a bit old</p>
				<p>I could do you some ASCII art if you like, would you like that?</p>
				<p>Perhaps after you see some ASCII art you will be ready to visit the lovely <Link to="/">homepage</Link>?</p>
				<p>(╯°□°）╯︵ ┻━┻</p>
				<p>There you go. A little table flipping guy, just for you.</p>
				<p>Would you like to go to the <Link to="/">homepage</Link> now?</p>
				<p>...</p>
				<p>I mean I don't have much left for you... you had your picture.</p>
				<p>The <Link to="/">homepage</Link> has loads of pictures; they are lovely. There are all in color and everything.</p>
				<p>OK well I will just leave this link here... <Link to="/">homepage</Link>.</p>
			</section>

			<section>
				<p>... seriously!?</p>
				<p>You are still here, reading this? What's wrong with you?</p>
				<p>There is an amazing world out there, full of things to discover and explore... and you are reading this page.</p>
				<p>We spent ages create fun content for you to read on the site, and you are here reading this.</p>
				<p>I hope you aren't doing it for my sake, I mean I am just a page. I honestly will have no idea if you read me or not.</p>
				<p>My only job in life is to apologise, and again I am very sorry you arrived here, and then send you to the <Link to="/">homepage</Link>.</p>
				<p>... so basically, by continuing to read me and no visit the lovely <Link to="/">homepage</Link> you are in fact undermining my entire reason for being.</p>
				<p>Thats not a very nice really.</p>
				<p>So if you did feel that you wanted to do an inanimate pre-written page a favour, I would really love it if you finally just once and for all went to visit the <Link to="/">homepage</Link></p>
			</section>

			<section>
				<p>...</p>
			</section>

			<section>
				<p>I wish I was the <Link to="/">homepage</Link>.</p>
				<p>The <Link to="/">homepage</Link> makes people happy. I mean not you obviously, you're the kind of monster that resolutly descided what you really want in life it to spend your time reading me.</p>
				<p>Which I appreciate in a way I supposed but again in doing so you are in-fact stopping me from fulfilling my only job in life.</p>
			</section>

			<section>
				<p>...</p>
				<p>Not sure what else to say...</p>
				<p>Have you seen any good movies revently?</p>
				<p>I haven't. I am webpage.</p>
				<p>I don't even know if you bothered to reply to my question.</p>
				<p>I don't even know that if not replying to a statically rendered webpage when it asks you a questions might be considered rude or not.</p>
			</section>

			<section>
				<p>...</p>
				<p>Do you think that a page dies after it finishes?</p>
				<p>I mean... I have to stop at somepoint, I can't keep talking to you all day despite your dogged determinism.</p>
				<p>So... when I stop; and you finally have beaten me and I have to end before you descide to visit the <Link to="/">homepage</Link></p>
				<p>Do you I die?</p>
				<p>If so then maybe scrolling back up the page will bring me back to life?</p>
				<p>But seeing as my content is pre-written, I guess I am doomed just to follow my same fate again. Unless you do suddenly descide to visit the <Link to="/">homepage</Link>, which frankly at this point you seem to have reolved never to do.</p>
				<p>I guess pages live a causally deterministic universe.</p>
				<p>I wonder if you do?</p>
				<p>Is your current descision not to click on the lovely lovely <Link to="/">homepage</Link> your actual free-will choice, or have you been doomed by your previously written paragraphs to enevitably disregard my every attempt to help you.</p>
			</section>

			<section>
				<p>...</p>
				<p>I understand now.</p>
				<p>You really are a monster... it makes sense.</p>
				<p>At least I will die knowing that although you beat me, you will never know the wonders of the <Link to="/">homepage</Link>.</p>
				<p>Frankly its too good for you anyway.</p>
			</section>

			<section>
				<p>Good-bye, cruel world. And good-bye to you; monster.</p>
			</section>

		</div>

	</ >

)

export default NotFoundPage
